import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  styles: [
    `
      :host {
        @apply relative flex w-full animate-fade-in flex-col rounded border bg-neutral-50 p-4 shadow-md sm:p-6 md:p-8;
      }

      .dark :host {
        @apply border-neutral-700 bg-neutral-900;
      }
    `,
  ],
  template: `
    <ng-content select="[header]" />
    <ng-content />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {}
